.contact-form {
  width: 100%;
  height: 375px;
  margin: auto;
  padding: 50px;
  border: 2px solid #ddd;
  border-radius: 15px;
  background-color: #f9f9f9;
  border: 20px;
  position: relative;
  min-height: calc(100vh - 200px);
  bottom: 80px;
}

.form-group {
  margin-bottom: 25px;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 2px solid #ddd;
  background-color: transparent;
  font-size: 16px;
}

.form-control:focus {
  outline: none;
  border-bottom: 2px solid #555;
}

label {
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

textarea.form-control {
  height: 80px;
}


.title-contacto{
  font-size: 24px;
  margin-top: 30px;
  margin-bottom: 5%;
  text-align: center;
  font-weight: 600;
  bottom: 0;
}
button {
  position: relative;
  cursor: pointer;
  font-weight: 700;
  font-family: Helvetica,"sans-serif";
  transition: all .2s;
  padding: 7px 20px;
  border-radius: 100px;
  background: rgb(129, 14, 14);
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  font-size: 15px;
  color: white;
  margin-left: 26%;
  margin-top: 5%;
  z-index: 1;
}

button:hover {
  background: rgb(129, 14, 14);;
}

button > svg {
  width: 34px;
  margin-left: 10px;
  transition: transform .3s ease-in-out;
  color: white;

}

button:hover svg {
  transform: translateX(5px);
}

button:active {
  transform: scale(0.95);
}

@media (min-width:992px) {
  .contact-form {
    width: 50%;
  }
  button {
    margin-left: 32%;
  }
  .contact-form {
    bottom: 40px;
  }
 
}
