@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300&display=swap');


.container-elegir {
    background-image: linear-gradient(180deg, #6a64641a 0%, #0000005b 100%), url('../img/fondo_1.jpg');
    border-radius: 10px;
    width: 90%;
    margin-top: 50px;
}

.container-elegir .card {
    background-color: rgba(255, 255, 255, 0.574);
    width: 90%;
    border-radius: 20px;
}

.container-elegir .card-title {
    color: rgb(129, 14, 14);
}

.img-card {
    padding-top: 10px;
}

.cont-img {
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-pres {
    padding-left: 90px;
    font-family: 'Roboto Mono', monospace;
}

/*-----------------------Responsive--------------------*/

@media (max-width: 575.98px) { 
	.text-pres {
        width: 90%;
        margin-top: 40px;
        padding-left: 70px;
        text-align: center;
    }
 }

@media (min-width: 576px) and (max-width: 767.98px) { 
	.text-pres {
        width: 90%;
        margin-top: 40px;
        padding-left: 70px;
        text-align: center;
    }
 }

@media (min-width: 768px) and (max-width: 991.98px) { 
	.text-pres {
        width: 90%;
        margin-top: 40px;
        padding-left: 70px;
        text-align: center;
    }	
 }

@media (min-width: 992px) and (max-width: 1199.98px)  { 

 }

@media (min-width: 1200px) and (max-width: 1399.98px)  { 
  
 }

@media (min-width: 1400px) {

}