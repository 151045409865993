.float{
	position:fixed;
	width:55px;
	height:55px;
	bottom:15px;
	right:15px;
	background-color:#25d366;
	color:#FFF;
	border-radius:100%;
	font-size:30px;
	box-shadow: 0px 0px 0px 5px rgba(37, 211, 102, 0.1);
  	z-index:100;
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
}

.float:hover{
	color: white;
}

.my-float{
	line-height: 0;
}

/*-----------------------Responsive--------------------*/

@media (max-width: 767.98px) {
    .float{
        width:50px;
        height:50px;
        bottom:10px;
        right:10px;
    }
 }

@media (min-width: 768px) and (max-width: 991.98px) { 

 }

@media (min-width: 992px)  { 

 }