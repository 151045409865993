@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");


.end-container {
  width: 100%;
  height: 10px;
  min-height: 550px;
  max-height: 400px;
  position: relative;
  display: flex;
  color: #fff;
}

.end-container::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(180deg, #8e8b8bb1 0%, #0000008c 100%), url('../img/fondo_6.jpg');
  background-size: cover;
  clip-path: polygon(0 33%, 100% 0, 100% 100%, 0% 100%);
  z-index: -1;
}

.end-container h1 {
  color: rgba(240, 230, 230, 0.79);
  font-size: 30px;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  width: 700px;
  width: 100%;
}

.end-container .cont-h1 {
  font-size: 18px;
  padding: 21px;
}

.end-container .content {
  padding-top: 220px;
}

.end-container .btnContacto {
  cursor: pointer;
  padding: 0.5rem 2rem;
  position: relative;
  font-size: 16px;
  border-radius: 30px;
  background-color: black;
  color: rgb(240, 230, 230);
  text-transform: uppercase;
  margin-left: 31%;

}

.end-container .btnContacto:hover {
  background-color: black;
  text-decoration: none;
}
.btnContacto a{
  outline: none;
}

@media (min-width:768px) {
  .end-container .cont-h1 {
    font-size: 24px;
    
  }

  .end-container h1 {
    color: rgba(240, 230, 230, 0.955);
    margin-top: 25px;
    padding: 0px;
  }

  .end-container .content {
    padding-top: 200px;
  }

  .end-container .btnContacto {
    margin-left: 39%;
  }


}