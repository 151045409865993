.cont-footer{
    background-color: #000000;
    color: white;
    position:relative;
    width: 100%;
    padding-top: 20px;
    bottom:0;
}
.cont-footer .lista-unidades ul{
    list-style: none;
    margin: 0 auto;

}
.list-unidades {
    list-style: none;
    color: white !important;
  }
  
  .list-unidades li a {
    text-decoration: none;
    color: white !important;
  }
  .cont-footer .list-unstyled li a{
    color:white;
    text-decoration: none;
  }
  .cont-list2{
    padding-left: 10%;
  }
  @media (max-width: 600px) {
    .cont-list2 {
    text-align: center; 
  }
    .cont-list3{
      text-align:center;
    }
  }