.unidadSeleccionada{
  border: 3px solid #810E0E;
  border-radius: 2px;
}

/*-----------------------Responsive--------------------*/

@media (max-width: 575.98px) { 
  .unidadSeleccionada{
    scale: 1.1;
  }
 }