body {
  background-color: #dedede;
  overflow-x: hidden;
}

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");
@import url(https://fonts.googleapis.com/css?family=Oswald:400,700);
@import url('https://fonts.googleapis.com/css2?family=Cabin&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
@import "~react-image-gallery/styles/css/image-gallery.css";

.inicio {
  margin-top: 820px;
  padding: 0 !important;
}

.principal {
  position: absolute;
  top: 0;
  left: 0;
  height: 900px;
  width: 100%;
}

.contImg1 {
  width: 100%;
  height: 100%;
  position: absolute;
}

.overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.img1 {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: -2;
  background-color: black;
}

.contenedoPrincipal {
  position: relative;
  top: 0;
  padding-top: 80px;
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-inline: auto;

}

.contenedoPrincipal p {
  font-size: 50px;
  color: white;
  font-family: Bitter;
  font-weight: 200;
  cursor: default;
  z-index: 2;
  transition: scale .5s;
  margin: 0;
  width: 700px;
}

.elementoServicio {
  height: 100%;
  width: 100%;
  z-index: 2;
}

.contenedoPrincipal p:hover {
  scale: 1.2;
}

.unidades {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 15px;
}

.unidades .title {
  background-color: rgba(255, 255, 255, 0.633);
  justify-content: center;
  width: 150px;
  height: 100%;
  text-align: center;
  color: rgb(0, 0, 0);
  padding-top: 0px;
  font-weight: 500;
  display: flex;/*QUita el flex los acomoda bien*/
  align-items: center; /* Centramos verticalmente los elementos */
}

.unidades .title1 {
  font-size: 18px;
  background-color: rgb(129, 14, 14);
  justify-content: center;
  width: 200px;
  height: 95px;
  text-align: center;
  color: white;
  padding-top: 35px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.unidades .subtitle {
  color: black;
  font-weight: 400;
  font-size: 14px;
  padding-top: 10px;
}


.home-1 .about {
  margin-top: 60px;
  text-align: center;
  color: rgb(0, 0, 0);
  font-family: "Poppins", sans-serif;

}

.home-1 .capitalH {
  margin-top: 50px;
  text-align: center;
  color: rgb(0, 0, 0);
  font-family: "Poppins", sans-serif;
}

.home-1 .about p {
  color: rgb(0, 0, 0);
  text-align: justify;
  font-family: "Poppins", sans-serif;
  width: 100%;
}

.small-image {
  max-width: 150px;
  height: auto;
}

.list-item-container {
  display: flex;
  align-items: center;
}

.icon-container {
  margin-right: 10px;
}


@keyframes bounce {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes show-checkmark {
  from {
    opacity: 0;
    transform: scale(0.5);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.home-1 .linear {
  background-color: rgb(129, 14, 14);
  opacity: 1;
  color: white;
  width: 300px;
  height: 2px;
  border: none;
  margin-top: -6px;
}

.home-1 .capitalH p {
  color: rgb(0, 0, 0);
  text-align: justify;
  font-family: "Poppins", sans-serif;
  width: 100%;
}

.imgQuienesSomos{
  max-width: 100%;
  width: 35%;
  border-radius: 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.liServicios{
  font-size: 18px;
}

/*-----------------------Responsive--------------------*/

@media (max-width: 575.98px) { 
	.principal {
    height: 700px;
  }
  .inicio {
    margin-top: 620px;
  }
  .imgQuienesSomos{
    width: 80%;
  }
  .unidades .title1 {
    font-size: 18px;
    background-color: rgb(129, 14, 14);
    justify-content: center;
    width: 100%;
    height: 65px;
    padding-top: 20px;
  }
  .unidades .title {
    width: 90%;
    height: 40px;
    margin-top: 10px;
  }
  .unidades .titleGarteel{
    margin-inline: 0px !important;
    height: 60px;
  }
  .liServicios{
    font-size: 14px;
  }
 }

@media (min-width: 576px) and (max-width: 767.98px) { 
	.principal {
    height: 800px;
  }
  .inicio {
    margin-top: 720px;
  }
  .imgQuienesSomos{
    width: 50%;
  }
  .unidades .title {
    width: 80%;
    height: 50px;
    margin-top: 10px;
  }
  .unidades .titleGarteel{
    margin-inline: 0px !important;
    height: 60px;
  }
 }

@media (min-width: 768px) and (max-width: 991.98px) { 
	.principal {
    height: 800px;
  }
  .inicio {
    margin-top: 720px;
  }
  .imgQuienesSomos{
    width: 40%;
  }
  .unidades .title {
    height: 95px;
  }
  .unidades .titleGarteel{
    margin-top: 20px;
    margin-inline: 0px !important;
    height: 80px;
  }
 }

@media (min-width: 992px) and (max-width: 1199.98px)  { 
	.imgQuienesSomos{
    width: 90%;
  }
 }

@media (min-width: 1200px) and (max-width: 1399.98px)  { 
  .imgQuienesSomos{
    width: 55%;
  }
 }

@media (min-width: 1400px) {

}