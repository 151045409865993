.navbar .nav-link{
  padding: 0;
  color: white !important;
  font-size: 18px;
  font-family: Montserrat;
}

.navbar .activo{
  border-bottom: 2px solid #810E0E;
}

.offcanvas-body .nav-link{
  width: fit-content;
}

.offcanvas-body .activo{
  border-bottom: 2px solid #810E0E;
}

.navbar{
  z-index: 3;
  height: 80px;
}

.linkOffCanvas{
  font-size: 25px !important;
  font-weight: 100 !important;
}

.rrssOffcanvas{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}

.rrssOffcanvas li i{
  line-height: 0;
  font-size: 24px;
}

.offcanvas {
  background-color: #000;
}

.imagenMenu {
	-webkit-transition: 800ms ease all;
	-moz-transition: 800ms ease all;
	-o-transition: 800ms ease all;
	transition: 800ms ease all;
	-webkit-transform: rotate(0deg);
  }
  
  .imagenMenu:hover {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
  }

  .nav-link, .nav-link:hover {
	  color: white;
  }